<template>
  <div>
     <div v-if="target_type==2">
      <!--<div class="back_view" @click="backSeek" v-if="showPdf">返回查看</div>-->
      <div
        v-if="showPdf"
        style="
          display: flex;
          justify-content: center;
          margin-bottom: 10px;
          font-size:15px;
          height: 30px;
          line-height:30px;
          margin-top:25px;
        "
      >
        <a-button  type="primary" :disabled="disabled1"
          @click.stop="prePage"
          style="width: 80px; height: 30px; border-radius: 8px; font-size: 15px;margin-right:5px;line-height:24px"
        >
          上一页
        </a-button>
        {{ currentPage }}/ {{ numPages }}
        <a-button type="primary" :disabled="disabled2"
          @click.stop="nextPage"
          style="width: 80px; height: 30px; border-radius: 8px; font-size: 15px;margin-left:5px;line-height:24px"
        >
          下一页
        </a-button>
      </div>
      <a-spin :spinning="isLoading" tip="文档加载中，请稍后...">
        <div class="pdf_cont" v-if="!showPdf" style="height:500px">
          <!-- <img src="../../static/images/pdf.png" alt="" />
          <div class="title">{{ pdfData.title }}</div>
          <div class="preview_seek">
            <span @click="previewSeek">预览查看</span>
          </div>
          <div class="preview_seek">
            <span @click="downloadPdf">下载PDF</span>
          </div> -->
        </div>
    <!-- </a-spin> -->
      
      <!--<pdf ref="pdf" :src="pdfData.pdf" v-if="showPdf"/>-->
      <!-- <a-spin :spinning="isLoading" tip="文档加载中，请稍后..." style="position: fixed;top:0;left: 0;right:0;bottom: 0;margin: auto;"> -->
      <div v-if="showPdf">
        <pdf
          :src="pdfSrc"
          :page="currentPage"
          @num-pages="numPages = $event"
          @page-loaded="currentPage = $event"
        >
          ></pdf
        >
      </div>
      </a-spin>

      <a-modal v-model="userInfo">
        <div class="userInfo">
          <img :src="employeeData.employeeAvatar" alt="" />
          <div>
            <div style="font-weight: bold; font-size: 16px">
              {{ employeeData.employeeName }}
            </div>
            <span style="color: #9b9b9b; font-size: 12px">{{
              employeeData.employeeMobile
            }}</span>
          </div>
        </div>
        <div class="userQrcode">
          <img :src="employeeData.qrCode" alt="" />
          <span style="margin-top: 10px; font-size: 12px; color: #9b9b9b"
          >长按二维码，加我企业微信</span
          >
        </div>
      </a-modal>
    </div>
    <div v-if="target_type == 3" class="article_page">
      <h2 class="article_title">{{ articleData.title }}</h2>
      <p>
        <span class="">{{ articleData.title }}</span>
      </p>
      <p>
        <span class="article_author">{{ articleData.author }}</span>
      </p>
      <div
        v-html="articleData.content"
        id="show_content"
        style="width: 98%; word-wrap: break-word"
      ></div>

      <a-modal v-model="userInfo">
        <div class="userInfo">
          <img :src="employeeData.employeeAvatar" alt="" />
          <div>
            <div style="font-weight: bold; font-size: 16px">
              {{ employeeData.employeeName }}
            </div>
            <span style="color: #9b9b9b; font-size: 12px">{{
              employeeData.employeeMobile
            }}</span>
          </div>
        </div>
        <div class="userQrcode">
          <img :src="employeeData.qrCode" alt="" />
          <span style="margin-top: 10px; font-size: 12px; color: #9b9b9b"
          >长按二维码，加我企业微信</span
          >
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import { getRadarApi, openUserInfoApi, getRadar } from "../../api/radar";
import axios from "axios";
import { wxConfig } from "../../utils/wxCodeAuth"

export default {
  components: {
    pdf,
  },
  data() {
    return {
      disabled1:true,
      disabled2:false,
      isLoading:false,
      url: window.location.href,
      //  微信用户数据
      showPdf: false,
      weChatUserData: {},
      link: "",
      //pdf数据
      pdfData: {},
      //文章数据
      articleData: {},
      //员工名片数据
      employeeData: {},
      openTime: "",
      clickId: "",
      userInfo: false,
      numPages: 0,
      pdfSrc: "",
      currentPage: 1,
      loading: false,
      shareOption: {}
    };
  },
  created() {
    //  获取参数
    this.target_type = this.$route.query.type;
    this.staff_id = this.$route.query.employee_id;
    this.radar_id = this.$route.query.id;
    this.target_id = this.$route.query.target_id;
    this.getUserData()
    this.openTime = new Date().getTime();
    //this.previewSeek()
  },
  /*destroyed() {
    var u = navigator.userAgent;
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    if (isiOS) {
      window.addEventListener('pagehide', e => this.beforeunloadFn(e))
    } else {
      window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    }
  },
  beforeDestroy() {
    this.beforeunloadFn()
  },*/
  methods: {
    beforeunloadFn(e) {
      const nDate = new Date().getTime();
      const ntime = nDate - this.openTime;
      console.log(ntime);
      const time = this.toHHmmss(ntime);
      let completionRate = this.getScrollTop();
      completionRate = completionRate.toFixed(2);
      console.log("页面关闭", "打开时间:", time, completionRate);
      const readData = {
        union_id: this.weChatUserData.unionid,
        nickname: this.weChatUserData.nickname,
        radarId: this.radar_id,
        linkId: this.target_id,
        employeeId: this.staff_id,
        clickId: this.clickId,
        readTime: time,
        finishRate: completionRate,
      };
      getRadar(readData).then((res) => {
        console.log(res);
      });
    },
    //跳转下载
    downloadPdf() {
      window.location.href = this.pdfData.pdf;
    },
    //返回查看
    backSeek() {
      this.showPdf = false;
    },
    //预览查看
    previewSeek() {
      console.log(this.pdfData.pdf);
      this.isLoading=true
      this.loading = true;
      this.pdfSrc = pdf.createLoadingTask({
        url: this.pdfData.pdf,
        //url:'http://test.api.bankmartech.com/static/2022/0706/1935/1657107329896362c57381dad31.pdf',
        cMapUrl: "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/",
        cMapPacked: true,
      });

      this.pdfSrc.promise.then((pdf) => {
        this.numPages = parseInt(pdf.numPages);
        this.currentPage = 1;
        this.$set(this, "docsPDF.numPages", pdf.numPages);
        console.log("总页码", this.numPages);
        console.log("当前页码", this.currentPage);
        
        this.loading = false;
        this.isLoading=false
        this.showPdf = true;
      });
    },
    //获取用户数据
    getUserData() {
      let that = this;
      openUserInfoApi({
        id: that.radar_id,
      }).then((res) => {
        if (res.data.openid === undefined) {
          let redirectUrl = location.protocol + "//" + location.host +
            "/auth/radar?id=" +
            that.radar_id +
            "&target=" +
            encodeURIComponent("/radar" + location.search);
          console.log("跳转链接", redirectUrl)
          window.location.href = redirectUrl
          return
        }
        this.weChatUserData = res.data;
        this.getRadarData();
      });
    },
    //  获取数据
    getRadarData() {
      let params = {
        union_id: this.weChatUserData.unionid,
        nickname: this.weChatUserData.nickname,
        avatar: this.weChatUserData.headimgurl,
        target_type: this.target_type,
        radar_id: this.radar_id,
        target_id: this.target_id,
        staff_id: this.staff_id,
      };
      getRadarApi(params).then((res) => {
        this.clickId = res.data.clickId;
        if (this.target_type == 1) {
          window.location.href = res.data.link;
          return
        } else if (this.target_type == 2) {
          this.pdfData = res.data;
          this.employeeData = res.data;
          if (this.employeeData.employeeCard == 1 && this.employeeData.isContact == 0) {
            this.userInfo = true;
          }
          document.title = "雷达PDF";
          this.previewSeek();
          //分享内容
          this.shareOption = {
            title: res.data.title, // 分享标题
            desc: res.data.pdfName, // 分享描述
            link: this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: location.protocol + process.env.VUE_APP_API_BASE_URL + "/static/pdf.png", // 分享图标
            success: function () {
              // 用户点击了分享后执行的回调函数
            }
          }
        } else if (this.target_type == 3) {
          this.articleData = res.data.article;
          this.employeeData = res.data;
          if (this.employeeData.employeeCard == 1  && this.employeeData.isContact == 0) {
            this.userInfo = true;
          }
          document.title = "雷达文章";
          //分享内容
          this.shareOption = {
            title: res.data.article.title, // 分享标题
            desc: res.data.article.desc, // 分享描述
            link: this.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: res.data.article.cover_url, // 分享图标
            success: function () {
              // 用户点击了分享后执行的回调函数
            }
          }
          console.log(this.articleData);
          //设置倒计时通知
          let that = this;
          let countDown = 1000;
          console.log(res);
          if (res.data.countDown) {
            countDown = res.data.countDown;
            countDown = parseInt(countDown) * 1000;
            console.log(countDown);
          }
          setTimeout(function () {
            that.beforeunloadFn();
          }, countDown);
        }

        //加载分享内容
        let that = this;
        let shareFunction = function () {   //需在用户可能点击分享按钮前就先调用
          console.log("分享内容2222", that.shareOption)
          wx.updateAppMessageShareData(that.shareOption);
          wx.updateTimelineShareData(that.shareOption);
        }

        //加载微信配置
        wxConfig(shareFunction);

      });
    },
    //  浏览时间
    toHHmmss(data) {
      var time;
      var hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.ceil((data % (1000 * 60)) / 1000);
      if (Number(hours)) {
        time =
          (hours < 10 ? "0" + hours : hours) +
          "时" +
          (minutes < 10 ? "0" + minutes : minutes) +
          "分" +
          (seconds < 10 ? "0" + seconds : seconds) +
          "秒";
      } else if (Number(minutes)) {
        time =
          (minutes < 10 ? "0" + minutes : minutes) +
          "分" +
          (seconds < 10 ? "0" + seconds : seconds) +
          "秒";
      } else {
        time = (seconds < 10 ? "0" + seconds : seconds) + "秒";
      }

      return time;
    },

    //  浏览程度
    getScrollTop() {
      var scroll_top = 0;
      if (document.documentElement && document.documentElement.scrollTop) {
        scroll_top = document.documentElement.scrollTop;
      } else if (document.body) {
        scroll_top = document.body.scrollTop;
      }
      const allHeight = Math.max(
        document.documentElement.scrollHeight,
        document.documentElement.clientHeight
      );
      const lookHeight = document.body.clientHeight;

      return ((lookHeight + scroll_top) / allHeight) * 100;
    },
    // 上一页
    prePage() {
      this.disabled2=false  
      var page = this.currentPage;
      page = page > 1 ? page - 1 : this.numPages;
      this.currentPage = page;
      if(this.currentPage==1){
        this.disabled1=true
      }
    },

    // 下一页
    nextPage() {
      var page = this.currentPage;
      this.disabled1=false  
      page = page < this.numPages ? page + 1 : 1;
      this.currentPage = page;
      if(this.currentPage==this.numPages){
        this.disabled2=true
      } 
    },

    // 回调
    loadPdfHandler(e) {
      this.currentPage = e;
    },
  },
};
</script>
<style scoped lang="less">
::v-deep img {
  width: 98%;
}
/deep/ .ant-modal-footer {
  display: none;
}

.pdf_cont {
  padding-top: 57px;
  text-align: center;

  img {
    width: 75px;
    height: 75px;
  }

  .title {
    margin-top: 20px;
    color: #000;
    font-size: 17px;
  }
}

.back_view {
  padding-left: 25px;
  margin-top: 25px;
  font-size: 16px;
  display: inline-block;
}

.userInfo {
  display: flex;
  align-items: center;
  //justify-content: space-between;
  img {
    width: 40px;
    margin-right: 10px;
    height: 40px;
  }
}

.userQrcode {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  img {
    width: 200px;
    height: 200px;
  }
}

.preview_seek {
  margin-top: 15px;
  font-size: 16px;
}

.article_page {
  padding: 15px;
  width: 100%;
  overflow-x: hidden;
}

/deep/ #js_content {
  visibility: visible !important;
}

/deep/ .rich_media_content {
  visibility: visible !important;
}

.article_title {
  font-weight: bold;
}
</style>
